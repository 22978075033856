<template>
	<cms-text :value="`${prefix}.content`" :props="{variant: 'medium-header', tag: value.header_tag, size: value.header_size }"/>
</template>

<script>

export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
